import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { IPatients } from '../../interfaces/patients';
import { Admission } from '../../interfaces/reports';
import { baseQuery } from './common';
import { IUser } from '../../interfaces/user';
import { IProduct } from '../../interfaces/products';
import { IDepartment } from '../../interfaces/department';
import { IInvoicePayload } from '../../interfaces/invoice';
import { IMedicationRequest } from '../../interfaces/medication';
import { IBloodBankPayload } from '../../interfaces/patientBloodManagement';
import { ILabRequest, IRadRequest } from '../../interfaces/lab';
import { Ivendor } from '../../interfaces/vendor';
import { IAppointment } from '../../interfaces/appointment';

export const dashboardApi = createApi({
	reducerPath: 'dashboard',
	baseQuery: baseQuery,
	tagTypes: [
		'Patients',
		'Patient',
		'Users',
		'User',
		'Template',
		'Products',
		'Departments',
		'Department',
		'Invoices',
		'Invoice',
		'Medications',
		'Medication',
		'patient-blood-management',
		'LabRequest',
		'RadRequest',
		'Vendor',
		'Vendors',
		'Appointment',
	],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getDashboardPatients: builder.query<IApiResponse<IPaginate<IPatients[]>>, { pagination: { page: number; limit: number | string } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getPatients?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Patients', id: 'LIST' }],
			keepUnusedDataFor: 300,
		}),

		getDashboardAdmittedPatients: builder.query<
			IApiResponse<IPaginate<Admission[]>>,
			{ pagination: { page: number; limit: number | string } }
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getPatientAdmissions?status[]=admitted&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Patients', id: 'LIST' }],
			keepUnusedDataFor: 300,
		}),
		getAllDashboardUsers: builder.query<IApiResponse<IPaginate<IUser[]>>, { pagination: { page: number; limit: number } }>({
			query: () => 'dashboard/getUsers?status[]=active&limit=10000',
			providesTags: [{ type: 'Users', id: 'LIST' }],
		}),
		getDashboardProducts: builder.query<IApiResponse<IPaginate<IProduct[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getProducts?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Products', id: 'LIST' }],
		}),
		getDashboardDepartment: builder.query<IApiResponse<IPaginate<IDepartment[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getDepartments?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Departments', id: 'LIST' }],
		}),
		getDashboardInvoicePaid: builder.query<
			IApiResponse<IInvoicePayload>,
			{
				pagination: { page: number; limit: number };
				searchTerm?: string;
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm, filterDateStart, filterDateEnd }) =>
				`invoice?page=${page}&limit=${limit}&status=paid${searchTerm && `&searchTerm=${searchTerm}`}${
					filterDateStart && `&startDate=${filterDateStart}`
				}${filterDateEnd && `&endDate=${filterDateEnd}`}`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		getDashboardMedicationOrder: builder.query<
			IApiResponse<IPaginate<IMedicationRequest[]>>,
			{ pagination: { page: number; limit: number } }
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getOrders?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Medications', id: 'LIST' }],
		}),
		getDashboardMedicationOrderCompletedWithDateFilter: builder.query<
			IApiResponse<IPaginate<IMedicationRequest[]>>,
			{
				pagination: { page: number; limit: number };
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, filterDateStart, filterDateEnd }) =>
				`dashboard/getOrders?page=${page}&limit=${limit}&status[]=completed${filterDateStart && `&startDate=${filterDateStart}`}${
					filterDateEnd && `&endDate=${filterDateEnd}`
				}&filterOperator=and`,
			providesTags: [{ type: 'Medications', id: 'LIST' }],
		}),
		getDashboardMedicationRequests: builder.query<
			IApiResponse<IPaginate<IMedicationRequest[]>>,
			{
				pagination: { page: number; limit: number };
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) =>
				`dashboard/getMedicationRequest/filter?status[]=unattended&status[]=in-progress&filterOperator=and&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Medications', id: 'LIST' }],
		}),
		getDashboardBloodBank: builder.query<IApiResponse<IBloodBankPayload>, void>({
			query: () => ({
				url: `dashboard/getBloodBank`,
				method: 'GET',
			}),
			providesTags: [{ type: 'patient-blood-management', id: 'LIST' }],
		}),
		getDashboardLabRequest: builder.query<IApiResponse<IPaginate<ILabRequest[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getLabs?status=specimen-due&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'LabRequest', id: 'LIST' }],
		}),
		getDashboardLabRequestForInprogress: builder.query<
			IApiResponse<IPaginate<ILabRequest[]>>,
			{ pagination: { page: number; limit: number } }
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getLabs?status=specimen-taken&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'LabRequest', id: 'LIST' }],
		}),
		getDashboardLabRequestForCompleted: builder.query<
			IApiResponse<IPaginate<ILabRequest[]>>,
			{ pagination: { page: number; limit: number } }
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getLabs?status=result-released&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'LabRequest', id: 'LIST' }],
		}),
		getDashboardRadRequest: builder.query<IApiResponse<IPaginate<IRadRequest[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getRadiology?status=specimen-due&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'RadRequest', id: 'LIST' }],
		}),
		getDashboardRadRequestInprogress: builder.query<IApiResponse<IPaginate<IRadRequest[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getRadiology?status=specimen-taken&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'RadRequest', id: 'LIST' }],
		}),
		getDashboardRadRequestCompleted: builder.query<IApiResponse<IPaginate<IRadRequest[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getRadiology?status=result-released&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'RadRequest', id: 'LIST' }],
		}),
		getDashboardVendors: builder.query<IApiResponse<IPaginate<Ivendor[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `dashboard/getVendors?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Vendors', id: 'LIST' }],
		}),
		getDashboardInprogressTherapuetic: builder.query<
			IApiResponse<IPaginate<Array<string | number | boolean>>>,
			{
				pagination: { page: number; limit: number };
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) =>
				`dashboard/getTherapeuticAdministrations/filter?status[]=in-progress&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Medications', id: 'LIST' }],
		}),
		getDashboardAppointment: builder.query<IApiResponse<IAppointment[]>, string>({
			query: (id) => `dashboard/getAppointments/${id}`,
			providesTags: (result, error, arg) => [{ type: 'Appointment', id: arg }],
		}),
	}),
});

export const {
	useGetDashboardPatientsQuery,
	useGetDashboardAdmittedPatientsQuery,
	useGetAllDashboardUsersQuery,
	useGetDashboardProductsQuery,
	useGetDashboardDepartmentQuery,
	useGetDashboardInvoicePaidQuery,
	useGetDashboardMedicationOrderQuery,
	useGetDashboardMedicationOrderCompletedWithDateFilterQuery,
	useGetDashboardMedicationRequestsQuery,
	useGetDashboardBloodBankQuery,
	useGetDashboardLabRequestQuery,
	useGetDashboardLabRequestForInprogressQuery,
	useGetDashboardLabRequestForCompletedQuery,
	useGetDashboardRadRequestQuery,
	useGetDashboardRadRequestInprogressQuery,
	useGetDashboardRadRequestCompletedQuery,
	useGetDashboardVendorsQuery,
	useGetDashboardInprogressTherapueticQuery,
	useGetDashboardAppointmentQuery,
} = dashboardApi;
