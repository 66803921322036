import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { baseQuery } from './common';
import { IPatientBill, IPatientBillItem } from '../../interfaces/patients';

export const patientsBillsApi = createApi({
	reducerPath: 'patients-bills',
	baseQuery: baseQuery,
	tagTypes: ['Patients bills', 'Patient bill'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getPaientBills: builder.query<
			IApiResponse<IPaginate<IPatientBill[]>>,
			{
				pagination: { page: number; limit: number };
				startDate?: string;
				endDate?: string;
				searchTerm?: string;
				patientStatus?: 'active' | 'deceased';
				filterBy?: 'creationDate' | 'lastModifiedDate' | 'status' | 'amount';
				startingAmount?: string;
				endingAmount?: string;
			}
		>({
			query: ({
				pagination: { page = 1, limit = 10 },
				startDate,
				endDate,
				searchTerm,
				patientStatus,
				startingAmount,
				endingAmount,
				filterBy,
			}) =>
				`patient-bills?page=${page}&limit=${limit}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}${
					searchTerm ? `&searchTerm=${searchTerm}` : ''
				}${patientStatus ? `&patientStatus=${patientStatus}` : ''}${startingAmount ? `&startingAmount=${startingAmount}` : ''}${
					endingAmount ? `&endingAmount=${endingAmount}${filterBy ? `&filterBy=${filterBy}` : ''}` : ''
				}`,
			providesTags: [{ type: 'Patients bills', id: 'LIST' }],
		}),
		getPatientBillById: builder.query<IApiResponse<IPatientBillItem>, string>({
			query: (id) => `/patient-bills/${id}`,
			keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'Patient bill', id: arg }],
		}),
	}),
});

export const { useGetPaientBillsQuery, useGetPatientBillByIdQuery } = patientsBillsApi;
