import React, { ElementType, useEffect } from 'react';
import { Field, FieldProps } from 'formik';
import { InputGroup, TagPicker } from 'rsuite';
import { Form, Input as RInput, InputPicker as RInputPicker } from 'rsuite';
import { DatePicker as RDatePicker } from 'rsuite';
import { SelectPicker as RSelectPicker } from 'rsuite';
import { capitalizeFirstLetter } from '../../../helpers';

interface IBaseInput {
	name: string;
	label?: string;
	withoutLabel?: boolean;
	required?: boolean;
}

interface ILabel extends IBaseInput {}

interface IInput extends IBaseInput {
	type?: string;
	as?: ElementType;
	[key: string]: string | any;
}

interface IDatePicker extends IBaseInput {
	[key: string]: string | any;
}
interface IInputPicker extends IBaseInput {
	data: { label: string; value: string }[];
	searchable?: boolean;
}

interface IInputPickerCustom extends IBaseInput {
	data: any;
	searchable?: boolean;
	noClean?: boolean;
	[key: string]: string | any;
}

interface IInuptAddon extends IInput {
	Addon: any | string;
}

export const Input = ({ name, type, label, withoutLabel, ...props }: IInput) => {
	return (
		<>
			<Field name={name}>
				{({ field: { onChange, ...field }, meta }: FieldProps) => (
					<>
						{withoutLabel == true ? null : (
							<div className="d-flex align-items-center">
								<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
								{props.required == false ? null : (
									<Form.HelpText tooltip className="mt-0">
										{label || capitalizeFirstLetter(name)} is required
									</Form.HelpText>
								)}
								{/* <br /> */}
							</div>
						)}
						{meta.touched && meta.error && (
							<Form.ErrorMessage placement="bottomStart" show>
								{meta.error}
							</Form.ErrorMessage>
						)}
						{/* <Form.Control type={type || `text`} onChange={(v: any, e: any) => onChange(v)} {...field} {...props} /> */}
						<RInput type={type || `text`} size="lg" onChange={(v, e) => onChange(e)} {...field} {...props} />
					</>
				)}
			</Field>
		</>
	);
};

export const InputWithoutLabel = ({ name, type, ...props }: IInput) => {
	return (
		<>
			<Field name={name}>
				{({ field: { onChange, ...field }, meta }: FieldProps) => (
					<>
						{(meta.touched || meta.value) && meta.error && (
							<Form.ErrorMessage placement="bottomStart" show>
								{meta.error}
							</Form.ErrorMessage>
						)}
						{/* <Form.Control type={type || `text`} onChange={(v: any, e: any) => onChange(v)} {...field} {...props} /> */}
						<RInput type={type || `text`} size="lg" onChange={(v, e) => onChange(e)} {...field} {...props} />
					</>
				)}
			</Field>
		</>
	);
};

export const Label = ({ name, label, required }: ILabel) => {
	return (
		<div className="d-flex align-items-center">
			<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
			{required == false ? null : (
				<Form.HelpText tooltip className="mt-0">
					{label || capitalizeFirstLetter(name)} is required
				</Form.HelpText>
			)}
		</div>
	);
};

export const InputPicker = ({ name, label, data, withoutLabel, ...props }: IInputPicker) => {
	return (
		<>
			<Field name={name}>
				{({ field: { onChange, ...field }, meta }: FieldProps) => (
					<>
						{withoutLabel == true ? null : (
							<div className="d-flex align-items-center">
								<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
								{props.required == false ? null : (
									<Form.HelpText tooltip className="mt-0">
										{label || capitalizeFirstLetter(name)} is required
									</Form.HelpText>
								)}
								{/* <br /> */}
							</div>
						)}

						{(meta.value || meta.value == null) && meta.error && (
							<Form.ErrorMessage placement="bottomStart" show>
								{meta.error}
							</Form.ErrorMessage>
						)}
						<RInputPicker
							block
							size="lg"
							labelKey="label"
							valueKey="value"
							data={data}
							onSelect={(v, i, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							onChange={(v, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							{...field}
							{...props}
						/>
					</>
				)}
			</Field>
		</>
	);
};

export const InputPickerCustom = ({
	name,
	label,
	data,
	withoutLabel,
	noClean,
	isMultiple = false,
	onSearch = () => {},
	...props
}: IInputPickerCustom) => {
	useEffect(() => {
		onSearch('');
	}, []);

	return (
		<Field name={name}>
			{({ field: { onChange, ...field }, meta }: FieldProps) => (
				<>
					{withoutLabel == true ? null : (
						<div className="d-flex align-items-center">
							<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
							{props.required == false ? null : (
								<Form.HelpText tooltip className="mt-0">
									{label || capitalizeFirstLetter(name)} is required
								</Form.HelpText>
							)}
							{/* <br /> */}
						</div>
					)}
					{(meta.value || meta.value == null) && meta.error && (
						<Form.ErrorMessage placement="bottomStart" show>
							{meta.error}
						</Form.ErrorMessage>
					)}

					{isMultiple ? (
						<TagPicker
							block
							size="lg"
							labelKey="label"
							cleanable={noClean ? false : true}
							valueKey="value"
							data={data}
							onSelect={(v, i, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							onChange={(v, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							onSearch={onSearch}
							// {...field}
							{...props}
						/>
					) : (
						<RInputPicker
							block
							size="lg"
							labelKey="label"
							cleanable={noClean ? false : true}
							valueKey="value"
							data={data}
							onSelect={(v, i, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							onChange={(v, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							onSearch={onSearch}
							// {...field}
							{...props}
						/>
					)}
					{/* <pre>{JSON.stringify({ meta, field }, null, 2)}</pre> */}
				</>
			)}
		</Field>
	);
};

export const SelectPicker = ({ name, label, data, withoutLabel, ...props }: IInputPicker) => {
	return (
		<>
			<Field name={name}>
				{({ field: { onChange, ...field }, meta }: FieldProps) => (
					<>
						{withoutLabel == true ? null : (
							<div className="d-flex align-items-center">
								<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
								{props.required == false ? null : (
									<Form.HelpText tooltip className="mt-0">
										{label || capitalizeFirstLetter(name)} is required
									</Form.HelpText>
								)}
								{/* <br /> */}
							</div>
						)}
						{(meta.value || meta.value == null) && meta.error && (
							<Form.ErrorMessage placement="bottomStart" show>
								{meta.error}
							</Form.ErrorMessage>
						)}
						<RSelectPicker
							block
							size="lg"
							labelKey="label"
							valueKey="value"
							data={data}
							onSelect={(v, i, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							onChange={(v, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							{...field}
							{...props}
						/>
					</>
				)}
			</Field>
		</>
	);
};

export const DatePicker = ({ name, label, withoutLabel, ...props }: IDatePicker) => {
	return (
		<>
			<Field name={name}>
				{({ field: { onChange, ...field }, meta }: FieldProps) => (
					<>
						{withoutLabel == true ? null : (
							<div className="d-flex align-items-center">
								<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
								{props.required == false ? null : (
									<Form.HelpText tooltip className="mt-0">
										{label || capitalizeFirstLetter(name)} is required
									</Form.HelpText>
								)}
								{/* <br /> */}
							</div>
						)}
						{(meta.value || meta.value == null) && meta.error && (
							<Form.ErrorMessage placement="bottomStart" show>
								{meta.error}
							</Form.ErrorMessage>
						)}
						<RDatePicker
							block
							size="lg"
							onSelect={(v, e) =>
								onChange({
									...e,
									target: { ...e?.target, id: name, name: name, value: v },
								})
							}
							onChange={(v, e) =>
								onChange({
									...e,
									target: { ...e.target, id: name, name: name, value: v },
								})
							}
							{...field}
							{...props}
						/>
					</>
				)}
			</Field>
		</>
	);
};

export const InputAddon = ({ name, type, label, Addon, withoutLabel, ...props }: IInuptAddon) => {
	return (
		<>
			<Field name={name}>
				{({ field: { onChange, ...field }, meta }: FieldProps) => (
					<>
						{withoutLabel == true ? null : (
							<div className="d-flex align-items-center">
								<Form.ControlLabel>{label || capitalizeFirstLetter(name)} </Form.ControlLabel>
								{props.required == false ? null : (
									<Form.HelpText tooltip className="mt-0">
										{label || capitalizeFirstLetter(name)} is required
									</Form.HelpText>
								)}
								<br />
							</div>
						)}
						{(meta.touched || meta.value) && meta.error && (
							<Form.ErrorMessage placement="bottomStart" show>
								{meta.error}
							</Form.ErrorMessage>
						)}
						<InputGroup className="mb-2" inside>
							<RInput type={type || `text`} size="lg" onChange={(v, e) => onChange(e)} {...field} {...props} />
							{Addon && <InputGroup.Addon className="py-0 px-0 h-100">{Addon}</InputGroup.Addon>}
						</InputGroup>
						{/* {meta.touched && meta.error && <span className="text-danger">{meta.error}</span>} */}
					</>
				)}
			</Field>
		</>
	);
};
