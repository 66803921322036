import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Container from 'react-bootstrap/Container';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import FlexButton from '../../components/elements/FlexButton';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Toast } from '../Auth/signup/signup';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { registerAsync } from '../../app/hospitalRedux/userSlice';
import HAD from './dashboardComponents/healthCareAdmin';
import AdminOfficerDB from './dashboardComponents/adminOfficer';
import api from '../../controllers/endpoints/api';
import ReceptionistDB from './dashboardComponents/receptionnist';
import LabTechDashboard from './dashboardComponents/labTech';
import ImagingTechDashboard from './dashboardComponents/imagingTech';
import AccountingDashboard from './dashboardComponents/accounting';
import DoctorDB from './dashboardComponents/doctor';
import NurseDB from './dashboardComponents/nurse';
import greenMark from '../../assets/images/greenMark.png';
import InventoryManagerDB from './dashboardComponents/inventoryMan';
import PhamacistDB from './dashboardComponents/phamacy';
import CustormUserDB from './dashboardComponents/custormUser';
import { useSelector } from 'react-redux';
import { selectTimer, start, stop, tick } from '../../app/hospitalRedux/hospitalActivationSlice';
import { useGetStaffProfileQuery } from '../../app/services/user';
import { IUser } from '../../interfaces/user';
import NewDashboardUserView, { IDashboardUserType } from './dashboardComponents/NewDashboardUserView';
import { em } from '@fullcalendar/core/internal-common';

const Box: React.FC<any> = ({ data: { name, icon, count, userCount } }) => (
	<div className=" px-4 py-4  h-100 col-12 d-flex flex-row shadow border rounded-3" style={{ maxWidth: '30rem', minWidth: '10rem' }}>
		<div className="col-9 d-flex flex-column justify-content-center">
			<p className="m-0">{count}</p>
			<p className="mb-2">{userCount}</p>
			<p className="fw-bold text-primary" style={{ fontSize: '0.9rem' }}>
				{name}
			</p>
		</div>
		<div className="col p-0">
			<img src={icon} alt="doctors" className="img-fluid" />
		</div>
	</div>
);

const BoxCard: React.FC<any> = ({ data, bgColor }) => (
	<div
		className=" px-4 py-4 d-flex text-dark flex-column shadow border rounded-3"
		style={{ maxWidth: '12rem', minWidth: '12rem', backgroundColor: bgColor }}
	>
		<p className="fw-bold m-0" style={{ fontSize: '0.9rem' }}>
			{data?.count}
		</p>
		<p className="mb-2 fw-bold">{data?.title}</p>
	</div>
);

interface SProfile {
	roleId: string;
	role: {
		id: string;
		name: string;
	};
}

export const CheckPermission = (permissionName: string): boolean => {
	const userPermission: any[] = JSON.parse(localStorage.getItem('user_permission') as string);

	//console.log(userPermission.filter( obj => obj.id === 'clfac4p1s004iqd0vsnybdwdv'));

	return userPermission?.some((item) => item?.name.replace(/\s+/g, '').toLowerCase() === permissionName.replace(/\s+/g, '').toLowerCase());
};

const { REACT_APP_BASEURL } = process.env;

const Dashboard = () => {
	const [phoneConfirmed, setPhoneConfirmed] = useState<Boolean>(true);
	const [refreshData, setRefreshData] = useState<Boolean>(true);

	const userToken = localStorage.getItem('token') as string;

	const dispatch = useAppDispatch();
	const hospitalActivationTimer = useAppSelector(selectTimer);

	// const canViewPat = CheckPermission('')

	const config = {
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
	};

	const loc = useLocation();
	const query = new URLSearchParams(loc.search);
	const email: any = query.get('email');
	const token: any = query.get('token');
	const [loading, setLoading] = useState(true);

	const history = useHistory();
	const [showMessage, setShowMessage] = useState('Phone number not verified');
	const [userStatus, setUserStatus] = useState('');
	const [showOtp, setShowOtp] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [otpLoading, setOtpLoading] = useState(false);

	const [showAlert, setShowAlert] = useState(true);

	const [errorMessage, setErrorMessage] = useState('');
	const [astericNumber, setAstericNumber] = useState('');
	const [dialCode, setdialCode] = useState('');

	const {
		data: staffProfileData,
		isLoading: staffProfileDataIsLoading,
		isError: staffProfileDataIsError,
	} = useGetStaffProfileQuery(0, { skip: email && token });

	const loguserIn = async () => {
		if (email && token) {
			localStorage.removeItem('signupInfo');
			try {
				const result: any = await dispatch(registerAsync({ token: token, data: { email: email } }));
				if (result.type == 'auth/registerUser/rejected') {
					Toast.fire({
						icon: 'warning',
						title: 'Network error',
					});
					history.push(`/${history.location.pathname.split('/')[1]}/login`);
				} else if (result.payload?.success && result.payload?.payload) {
					dispatch(start(Date.now()));
					// setNavigateToLogin(true);
					// const { phoneNumber, dialCode } = JSON.parse(
					//   localStorage.getItem("hospital_info") as string,
					// );
					// const hideNumber =
					//   phoneNumber.substring(0, 3) + "****" + phoneNumber.substring(7);
					// setAstericNumber(hideNumber);
					// setdialCode(dialCode);
					// setShowModal(true);
				} else {
					Toast.fire({
						icon: 'warning',
						title: result.payload ? `${result.payload}` : 'Kindly try again',
					});
					history.push(`/${history.location.pathname.split('/')[1]}/login`);
				}
			} catch (error) {
				console.log('error');
				Toast.fire({
					icon: 'warning',
					title: `Kindly try again`,
				});
				history.push(`/${history.location.pathname.split('/')[1]}/login`);
			}
		}
	};
	useEffect(() => {
		loguserIn();
	}, []);

	useEffect(() => {
		let timerId: NodeJS.Timeout;

		if (hospitalActivationTimer.isRunning && hospitalActivationTimer.startTime) {
			timerId = setInterval(() => {
				const elapsedTime = Date.now() - hospitalActivationTimer.startTime!;
				const remainingTime = hospitalActivationTimer.duration - elapsedTime;
				if (remainingTime <= 0) {
					dispatch(stop());
					clearInterval(timerId);
					localStorage.clear();
					history.push(`/${history.location.pathname.split('/')[1]}/login`);
				} else {
					dispatch(tick(remainingTime));
				}
			}, 1000);
		}

		return () => {
			if (timerId) {
				clearTimeout(timerId);
			}
		};
	}, [hospitalActivationTimer.isRunning, hospitalActivationTimer.startTime, dispatch]);

	const handleClose = () => {
		setErrorMessage('');
		formik.values.otp = '';
		setUserStatus('Hospital Admin');
		setShowModal(false);
	};

	const initialValues = {
		otp: '',
	};

	const initialMinute = 2;
	const initialSeconds = 0;
	const [minutes, setMinutes] = useState(initialMinute);
	const [seconds, setSeconds] = useState(initialSeconds);

	const validationSchema = yup.object({
		otp: yup
			.string()
			.required()
			.matches(/^[0-9]+$/, 'Must be only digits'),
	});

	const onSubmit = async (values: any) => {
		setErrorMessage('');
		setOtpLoading(true);
		try {
			const { phoneNumber } = JSON.parse(localStorage.getItem('hospital_info') as string);
			const res: any = await axios.post(`${REACT_APP_BASEURL}auth/verifyPhoneNumber/${formik.values.otp}`, { phoneNumber });
			if (res.data.success) {
				setOtpLoading(false);
				setShowAlert(false);
				toast.success('Phone number successfully verified');
				setShowModal(false);
				setRefreshData(!refreshData);
			}
		} catch (error: any) {
			setErrorMessage(error?.response?.data?.error?.message);
			toast.error('Failed to verify');
			setOtpLoading(false);
			// toast.error('incorrect otp')
		}
	};

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	}, [minutes, seconds]);

	const resend = async () => {
		setMinutes(2);
		setSeconds(0);
		const { phoneNumber } = JSON.parse(localStorage.getItem('hospital_info') as string);
		try {
			const res: any = await axios.post(`${REACT_APP_BASEURL}auth/sendPhoneNumberOTP`, { phoneNumber });
			if (res.data.success) {
				toast.success('otp sent sucessfully');
			}
		} catch (error) {
			toast.error("couldn't send otp");
		}
	};

	const getOtp = async () => {
		setShowOtp(true);
		const { phoneNumber } = JSON.parse(localStorage.getItem('hospital_info') as string);
		try {
			await axios.post(`${REACT_APP_BASEURL}auth/sendPhoneNumberOTP`, {
				phoneNumber,
			});
		} catch (error) {
			toast.error("couldn't send otp");
		}
	};

	const checkPhoneNumberVerification = async () => {
		const res = await api.get('auth/profile', userToken);
		console.log(res);
		setPhoneConfirmed(res?.data?.payload?.user?.phoneNumberConfirmed);
		// setLoggedInUser(res?.data?.payload?.user?)
		if (res?.data?.success && res?.data?.payload?.user?.phoneNumberConfirmed == false) {
			setUserStatus(res?.data?.payload?.user?.role?.name);
		}
	};

	useEffect(() => {
		if (!email && !token) {
			CheckPermission('View User Profile') && checkPhoneNumberVerification();
		}
	}, [refreshData]);

	const formik: { [key: string]: any } = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	return (
		<>
			{staffProfileDataIsLoading ? (
				<div className="min-vh-100">
					<div className="d-flex py-5 justify-content-center text-center">
						<Spinner animation="border" className="my-5" role="status" variant="primary" style={{ width: '5rem', height: '5rem' }}>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				</div>
			) : staffProfileData?.success && staffProfileData?.payload ? (
				<div className="min-vh-100">
					<div className="px-lg-1 px-1 mt-4 mb-3 d-flex justify-content-between">
						<h3 className="mt-2">{staffProfileData?.payload ? staffProfileData?.payload.role.name : ''}</h3>
						<div className="d-flex flex-row gap-5 align-items-center">
							{phoneConfirmed
								? ''
								: CheckPermission('View Get Started') && (
										<Card
											onClick={() => setShowModal(true)}
											className="shadow-sm border rounded border-0 d-flex flex-row gap-2 align-items-center justify-content-center"
											style={{
												minWidth: '23.5rem',
												minHeight: '3.5625rem',
												backgroundColor: '#F6FBFE',
												cursor: 'pointer',
											}}
										>
											<i className="bi bi-sun" style={{ color: '#EAB27A', fontSize: 24 }}></i>
											You have not verified your phone number
										</Card>
								  )}

							<div className="d-flex flex-row  gap-3 align-items-center">
								{CheckPermission('View Get Started') && (
									<Button
										onClick={() => history.push('/hospital/getstarted')}
										variant="bg-transparent border border-1 border-primary"
										className="text-primary"
									>
										Get Started
									</Button>
								)}
								{/* <p className="p-0 m-0 text-primary" style={{textDecoration:"underline", cursor:'pointer'}}></p> */}
								{/* <i className="bi bi-asterisk text-success" style={{cursor:'pointer'}}></i> */}

								{CheckPermission('View Civis Documentation') && (
									<p
										className="d-flex text-center p-3"
										onClick={() => {
											history.push('/hospital/documentation');
										}}
									>
										<i
											className="bi bi-journals text-primary"
											style={{
												cursor: 'pointer',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										></i>
									</p>
								)}
							</div>
						</div>
					</div>

					<NewDashboardUserView userType={staffProfileData ? (staffProfileData.payload.role.name as IDashboardUserType) : null} />
					{/*logedInUser?.role?.name == "Healthcare Admin Manager" ? (
                <HAD
                  dontRemTopLine={true}
                  remBBandLab={false}
                  remBillandLmo={false}
                />
              ) : logedInUser?.role?.name == "Super Admin" ? (
                <HAD
                  dontRemTopLine={true}
                  remBBandLab={false}
                  remBillandLmo={false}
                />
              ) : logedInUser?.role?.name == "Auditor" ? (
                <HAD
                  dontRemTopLine={true}
                  remBBandLab={false}
                  remBillandLmo={false}
                />
              ) : logedInUser?.role?.name == "Lab Supervisor" ? (
                <LabTechDashboard />
              ) : logedInUser?.role?.name == "Lab Technician" ? (
                <LabTechDashboard />
              ) : logedInUser?.role?.name == "Accountant/Finance Manager" ? (
                <AccountingDashboard />
              ) : logedInUser?.role?.name == "Admin Officer" ? (
                <AdminOfficerDB />
              ) : logedInUser?.role?.name == "Doctor/Physician" ? (
                <DoctorDB />
              ) : logedInUser?.role?.name == "Head Doctor" ? (
                <DoctorDB />
              ) : logedInUser?.role?.name == "Head Nurse" ? (
                <NurseDB />
              ) : logedInUser?.role?.name == "Nurse" ? (
                <NurseDB />
              ) : logedInUser?.role?.name == "Head Pharmacist" ? (
                <PhamacistDB />
              ) : logedInUser?.role?.name == "Pharmacist" ? (
                <PhamacistDB />
              ) : logedInUser?.role?.name == "Receptionist" ? (
                <ReceptionistDB />
              ) : logedInUser?.role?.name == "Imaging Supervisor" ? (
                <ImagingTechDashboard />
              ) : (
                <CustormUserDB />
              )*/}
				</div>
			) : null}

			<Modal show={staffProfileDataIsError} backdrop="static" centered>
				<Modal.Body>
					<h6 className="text-danger p-3 text-center">Error fetching staff profile</h6>
					<div className="text-center">
						<Button size="sm" onClick={() => window.location.reload()}>
							Refresh
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={email && token} backdrop="static" centered>
				<Modal.Body>
					<p className="text-center text-danger p-3">Please Wait... You will be redirected to the sign-in screen once the setup is complete</p>
					<div className="mt-1 mb-5 mx-5">
						<div className="d-flex justify-content-between py-1">
							<p className="text-primary">Hospital Setup</p>
							{hospitalActivationTimer.isRunning && hospitalActivationTimer.remainingTime < 4000 ? (
								<img src={greenMark} className="mr-4" width="10%" />
							) : (
								<Spinner animation="grow" variant="primary" size="sm" />
							)}
						</div>

						<div className="d-flex justify-content-between py-1">
							<p className="text-primary">Test List Setup</p>
							{hospitalActivationTimer.isRunning && hospitalActivationTimer.remainingTime < 3000 ? (
								<img src={greenMark} className="mr-4" width="10%" />
							) : (
								<Spinner animation="grow" variant="primary" size="sm" />
							)}
						</div>

						<div className="d-flex justify-content-between py-1">
							<p className="text-primary">Billing Item Setup</p>
							{hospitalActivationTimer.isRunning && hospitalActivationTimer.remainingTime < 2000 ? (
								<img src={greenMark} className="mr-4" width="10%" />
							) : (
								<Spinner animation="grow" variant="primary" size="sm" />
							)}
						</div>

						<div className="d-flex justify-content-between py-1">
							<p className="text-primary">Finishing Setup</p>
							{hospitalActivationTimer.isRunning && hospitalActivationTimer.remainingTime < 1000 ? (
								<img src={greenMark} className="mr-4" width="10%" />
							) : (
								<Spinner animation="grow" variant="primary" size="sm" />
							)}
						</div>
					</div>
					{/*<div className="d-flex justify-content-end">
            <Button
              disabled={navigateToLogin||hospitalActivationTimer.remainingTime < 1000}
              variant="primary"
              onClick={() => {
                localStorage.clear();
                setNavigateToLogin(false);
                history.push(
                  `/${history.location.pathname.split("/")[1]}/login`,
                );
              }}
            >
              Proceed To Login
            </Button>
          </div>*/}
				</Modal.Body>
			</Modal>

			<Modal show={showModal} backdrop="static" onHide={handleClose} centered>
				<Modal.Header className="modal-header border-0 justify-content-end">
					<i className="bi bi-x-circle" style={{ cursor: 'pointer' }} onClick={() => setShowModal(false)}></i>
				</Modal.Header>
				<Modal.Body className="px-5">
					{!showOtp ? (
						<div>
							<h5 className="text-center mb-4">Phone Verification</h5>
							<p>
								Please verify your phone number by clicking the <strong>verify</strong> button below
							</p>
							<FlexButton
								color="flexblue"
								onClick={() => getOtp()}
								style={{
									padding: '.6rem 4rem',
									backgroundColor: '#0F4E71',
									borderRadius: '15px',
									margin: '2rem',
								}}
							>
								Verify
							</FlexButton>
						</div>
					) : (
						<Form onSubmit={formik.handleSubmit}>
							<h5 className="text-center mb-4">Phone number verification</h5>
							<p>Plese provide the six digit OTP sent to your registered phone number.</p>
							<Form.Group className="mt-5">
								<Form.Control
									style={{
										margin: 'auto',
										fontSize: '1rem',
										padding: '.7rem',
										borderRadius: '15px',
									}}
									className="p-4 px-3 mb-3"
									type="text"
									id="otp"
									name="otp"
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									value={formik.values.otp}
									placeholder="OTP"
								/>
								{formik.errors.otp && formik.touched.otp ? (
									<p className="text-center" style={{ color: 'red', fontSize: '10px' }}>
										{formik.errors.otp}
									</p>
								) : null}
							</Form.Group>
							<div className="mb-1 d-flex justify-content-between" style={{ fontSize: '1rem' }}>
								{minutes === 0 && seconds === 0 ? <p>Didn't get OTP?</p> : ''}
								<div className="ml-auto">
									{minutes === 0 && seconds === 0 ? (
										<p style={{ cursor: 'pointer', color: '#339CE8' }} onClick={() => resend()}>
											Resend OTP
										</p>
									) : (
										<p>
											{' '}
											{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
										</p>
									)}
								</div>
							</div>

							<p className="text-danger mb-4">{errorMessage}</p>

							<FlexButton
								color="flexblue"
								disabled={otpLoading}
								type="submit"
								style={{
									padding: '1rem 5rem',
									backgroundColor: '#0F4E71',
									borderRadius: '10px',
								}}
							>
								{otpLoading ? 'Loading...' : 'COMPLETE'}
							</FlexButton>
						</Form>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default Dashboard;
