import { ILineOneComponent } from '../../pages/HospitalAdmin/dashboardComponents/LineOneComponent';
import PatientIcon from '../../assets/images/heart.png';
import DoctorsIcon from '../../assets/images/doctors.png';
import NurseIcon from '../../assets/images/injection.png';
import PharmacyIcon from '../../assets/images/ph_pill-thin.png';
import LabIcon from '../../assets/images/lab.png';
import AmbulanceIcon from '../../assets/images/healthicons_ambulance-outline.png';
import OperationsIcon from '../../assets/images/ph_scissors-thin.png';
import BirthIcon from '../../assets/images/footprint.png';
import DeathIcon from '../../assets/images/tombstone.png';
import { IDashboardUserType } from '../../pages/HospitalAdmin/dashboardComponents/NewDashboardUserView';
import { IUserCard } from '../../pages/HospitalAdmin/dashboardComponents/UserCard';
import { IUser } from '../../interfaces/user';
import moment from 'moment';
import { IDepartment } from '../../interfaces/department';
import { IDepartmentTable } from '../../pages/HospitalAdmin/dashboardComponents/DepartmentTable';
import { IInvoicePayload } from '../../interfaces/invoice';
import { IMedicationRequest } from '../../interfaces/medication';
import { IAppointment } from '../../interfaces/appointment';
import { IProduct } from '../../interfaces/products';
import { CheckPermission } from '../../pages/HospitalAdmin/Dashboard';
import {
	useGetDashboardPatientsQuery,
	useGetDashboardAdmittedPatientsQuery,
	useGetAllDashboardUsersQuery,
	useGetDashboardProductsQuery,
	useGetDashboardDepartmentQuery,
	useGetDashboardInvoicePaidQuery,
	useGetDashboardMedicationOrderQuery,
	useGetDashboardMedicationOrderCompletedWithDateFilterQuery,
	useGetDashboardMedicationRequestsQuery,
	useGetDashboardBloodBankQuery,
	useGetDashboardLabRequestQuery,
	useGetDashboardLabRequestForInprogressQuery,
	useGetDashboardLabRequestForCompletedQuery,
	useGetDashboardRadRequestQuery,
	useGetDashboardRadRequestInprogressQuery,
	useGetDashboardRadRequestCompletedQuery,
	useGetDashboardVendorsQuery,
	useGetDashboardInprogressTherapueticQuery,
	useGetDashboardAppointmentQuery,
} from '../services/dashboard';
import { useGetCurrencyQuery } from '../services/currency';

interface ReturnDataType {
	LineOneData: Array<ILineOneComponent | undefined>;
	UsersCardData: Array<IUserCard>;
	DepartmentTableData: Array<IDepartmentTable>;
	PaidInvoiceData: IInvoicePayload | undefined;
	CurrencyCode: string | undefined;
	MedicationOrderData: Array<IMedicationRequest> | undefined;
	CurrentDateMedication: Array<IMedicationRequest> | undefined;
	BloodBankData: Array<IDepartmentTable>;
	LabRequestData: Array<IDepartmentTable>;
	RadRequestData: Array<IDepartmentTable>;
	MedicationRequest: Array<IMedicationRequest> | undefined;
	FilteredAppointment: { [key: string]: IAppointment[] | undefined };
	InstockAndOutOfStockProducts: IProduct[] | undefined;
}
const useHandleDashboardUserData = (userType: IDashboardUserType): ReturnDataType => {
	const userId = localStorage.getItem('userId') as string;

	const { data: patientsData } = useGetDashboardPatientsQuery({
		pagination: { page: 1, limit: 10000000000 },
	});

	const { data: admittedPatientsData } = useGetDashboardAdmittedPatientsQuery({
		pagination: { page: 1, limit: 1000 },
	});

	const { data: usersData } = useGetAllDashboardUsersQuery({
		pagination: { page: 1, limit: 1000 },
	});

	const { data: productsData } = useGetDashboardProductsQuery({
		pagination: { page: 1, limit: 1000 },
	});

	const { data: departmentsData } = useGetDashboardDepartmentQuery({
		pagination: { page: 1, limit: 1000 },
	});

	const { data: paidInvoiceForLast30Days } = useGetDashboardInvoicePaidQuery({
		pagination: { page: 1, limit: 1000 },
		searchTerm: '',
		filterDateEnd: moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss ZZ').format('YYYY-MM-DD'),
		filterDateStart: moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss ZZ').subtract(30, 'days').format('YYYY-MM-DD'),
	});

	const { data: medicationOrderData } = useGetDashboardMedicationOrderQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: currencyValue } = useGetCurrencyQuery();

	const { data: completetdMedicationOrderForCurrentDate } = useGetDashboardMedicationOrderCompletedWithDateFilterQuery({
		pagination: { page: 1, limit: 10 },
		filterDateEnd: moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss ZZ').format('YYYY-MM-DD'),
		filterDateStart: moment(new Date(), 'ddd MMM DD YYYY HH:mm:ss ZZ').format('YYYY-MM-DD'),
	});

	const { data: medicationRequestData } = useGetDashboardMedicationRequestsQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: bloodBankData } = useGetDashboardBloodBankQuery();

	const { data: labRequestUnattendedData } = useGetDashboardLabRequestQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: labRequestInprogressData } = useGetDashboardLabRequestForInprogressQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: labRequestCompletedData } = useGetDashboardLabRequestForCompletedQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: radRequestUnattendedData } = useGetDashboardRadRequestQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: radRequestInprogressData } = useGetDashboardRadRequestInprogressQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: radRequestCompletedData } = useGetDashboardRadRequestCompletedQuery({
		pagination: { page: 1, limit: 5000 },
	});

	const { data: vendorData } = useGetDashboardVendorsQuery({
		pagination: { page: 1, limit: 1000 },
	});

	const { data: therapeuticData } = useGetDashboardInprogressTherapueticQuery({
		pagination: { page: 1, limit: 1000 },
	});

	const { data: appointmentData } = useGetDashboardAppointmentQuery(userId);

	const filterUserRole = (param: string): IUser[] | undefined => {
		let user = usersData?.payload && usersData.payload.data.filter((userData) => userData.role.name.toLowerCase().includes(param));
		return user;
	};

	const filterDepartments = (param: string): IDepartment[] | undefined => {
		let department =
			departmentsData?.payload && departmentsData.payload.data.filter((departmentData) => departmentData.name.toLowerCase().includes(param));
		return department;
	};

	const filterAppointments = (): {
		[key: string]: IAppointment[] | undefined;
	} => {
		let currentDateAppointment = appointmentData?.payload.filter(
			(appointment) => moment(appointment?.appointmentDate).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')
		);

		let scheduled = appointmentData?.payload.filter(
			(appointment) =>
				appointment.status === 'Scheduled' &&
				moment(appointment?.appointmentDate).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')
		);

		let inProgress = appointmentData?.payload.filter(
			(appointment) =>
				appointment.status === 'In-progress' &&
				moment(appointment?.appointmentDate).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')
		);

		let completed = appointmentData?.payload.filter(
			(appointment) =>
				appointment.status === 'Completed' &&
				moment(appointment?.appointmentDate).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')
		);

		return {
			currentDateAppointment,
			scheduled,
			inProgress,
			completed,
		};
	};

	const filterInstockAndOutOfStockProducts = (): IProduct[] | undefined => {
		let instockAndOutOfStockProducts =
			productsData?.payload && productsData.payload.data.filter((product) => product.status === 'enabled' && !product.isExpired);
		return instockAndOutOfStockProducts;
	};

	const LineOneData: Array<ILineOneComponent | undefined> = [
		// userType === "Healthcare Admin Manager" ||
		// userType === "Auditor" ||
		// userType === "Super Admin" ||
		// userType === "Admin Officer" ||
		// userType === "Receptionist" ||
		// userType.includes("Doctor") ||
		// userType.includes("Nurse") ||
		// userType === "Accountant/Finance Manager"
		CheckPermission('View Patient Card')
			? {
					name: 'Patients',
					count: patientsData?.payload.meta.count || 0,
					icon: PatientIcon,
					url: '/hospital/patients',
			  }
			: undefined,
		// userType === "Healthcare Admin Manager" ||
		// userType === "Auditor" ||
		// userType === "Super Admin" ||
		// userType === "Admin Officer" ||
		// userType === "Receptionist" ||
		// userType.includes("Doctor") ||
		// userType.includes("Nurse") ||
		// userType === "Accountant/Finance Manager"
		CheckPermission('View Inpatient Card')
			? {
					name: 'Inpatients',
					count: admittedPatientsData?.payload?.meta?.count || 0,
					icon: DoctorsIcon,
					url: '/hospital/admissions',
			  }
			: undefined,
		// userType === "Healthcare Admin Manager" ||
		// userType === "Auditor" ||
		// userType === "Super Admin" ||
		// userType === "Admin Officer" ||
		// userType === "Receptionist"
		CheckPermission('View Staff Card')
			? {
					name: 'Staffs',
					count: usersData?.payload?.meta?.count || 0,
					icon: NurseIcon,
					url: '/hospital/settings/users',
			  }
			: undefined,
		// userType === "Healthcare Admin Manager" ||
		// userType === "Auditor" ||
		// userType === "Super Admin" ||
		// userType === "Admin Officer" ||
		// userType === "Inventory Manager" ||
		// userType.includes("Pharmacist") ||
		// userType === "Accountant/Finance Manager"
		CheckPermission('View Inventory Product Card')
			? {
					name: 'Inventory',
					count: productsData?.payload?.meta?.count || 0,
					icon: PharmacyIcon,
					url: '/hospital/inventory',
			  }
			: undefined,
		// userType === "Inventory Manager"
		CheckPermission('Vendor Card')
			? {
					name: 'Vendor',
					count: vendorData?.payload?.meta?.count || 0,
					icon: PharmacyIcon,
					url: '/hospital/vendor',
			  }
			: undefined,
		// userType.includes("Pharmacist")
		CheckPermission('Therapeutic Administration Card')
			? {
					name: 'Pending Therapeutic Administration',
					count: therapeuticData?.payload?.meta?.count || 0,
					icon: NurseIcon,
					url: '/hospital/administrations',
			  }
			: undefined,
	];

	const UsersCardData: Array<IUserCard> = [
		{
			name: 'Accountants',
			count: filterUserRole('accountant')?.length || 0,
			icon: PatientIcon,
		},
		{
			name: 'Admins',
			count: (filterUserRole('admin')?.length || 0) + (filterUserRole('auditor')?.length || 0),
			icon: DoctorsIcon,
		},
		{
			name: 'Doctors',
			count: filterUserRole('doctor')?.length || 0,
			icon: NurseIcon,
		},
		{
			name: 'Image Technicians',
			count: filterUserRole('imaging')?.length || 0,
			icon: PharmacyIcon,
		},
		{
			name: 'Inventory Personel',
			count: filterUserRole('inventory')?.length || 0,
			icon: LabIcon,
		},
		{
			name: 'Lab Technicians',
			count: filterUserRole('lab')?.length || 0,
			icon: AmbulanceIcon,
		},
		{
			name: 'Nurses',
			count: filterUserRole('nurse')?.length || 0,
			icon: OperationsIcon,
		},
		{
			name: 'Phamacists',
			count: filterUserRole('pharmacist')?.length || 0,
			icon: BirthIcon,
		},
		{
			name: 'Death Report',
			count: (patientsData?.payload && patientsData.payload.data.filter((patient) => patient.status === 'deceased').length) || 0,
			icon: DeathIcon,
		},
	];

	const DepartmentTableData: Array<IDepartmentTable> = [
		{
			name: 'Account',
			count: Number(filterDepartments('account')?.[0]?.userCount) || 0,
		},
		{
			name: 'Admin Department',
			count: Number(filterDepartments('admin')?.[0]?.userCount) || 0,
		},
		{
			name: 'Pathology Department',
			count: Number(filterDepartments('pathology')?.[0]?.userCount) || 0,
		},
		{
			name: 'Medical Department',
			count: Number(filterDepartments('medical')?.[0]?.userCount) || 0,
		},
		{
			name: 'Nursing Department',
			count: Number(filterDepartments('nursing')?.[0]?.userCount) || 0,
		},
	];

	const BloodBankData: Array<IDepartmentTable> = [
		{
			name: 'A+',
			count: bloodBankData?.payload?.bloodBankDetail?.a_positive?.availableQuantity || 0,
		},
		{
			name: 'A-',
			count: bloodBankData?.payload?.bloodBankDetail?.a_negative?.availableQuantity || 0,
		},
		{
			name: 'B+',
			count: bloodBankData?.payload?.bloodBankDetail?.b_positive?.availableQuantity || 0,
		},
		{
			name: 'B-',
			count: bloodBankData?.payload?.bloodBankDetail?.b_negative?.availableQuantity || 0,
		},
		{
			name: 'AB+',
			count: bloodBankData?.payload?.bloodBankDetail?.ab_positive?.availableQuantity || 0,
		},
		{
			name: 'AB-',
			count: bloodBankData?.payload?.bloodBankDetail?.ab_negative?.availableQuantity || 0,
		},
		{
			name: 'O+',
			count: bloodBankData?.payload?.bloodBankDetail?.o_positive?.availableQuantity || 0,
		},
		{
			name: 'O-',
			count: bloodBankData?.payload?.bloodBankDetail?.o_negative?.availableQuantity || 0,
		},
	];

	const LabRequestData: Array<IDepartmentTable> = [
		{
			name: 'Unattended',
			count: labRequestUnattendedData?.payload.meta.count || 0,
		},
		{
			name: 'InProgress',
			count: labRequestInprogressData?.payload?.meta.count || 0,
		},
		{
			name: 'Completed',
			count: labRequestCompletedData?.payload?.meta?.count || 0,
		},
	];

	const RadRequestData: Array<IDepartmentTable> = [
		{
			name: 'Unattended',
			count: radRequestUnattendedData?.payload.meta.count || 0,
		},
		{
			name: 'InProgress',
			count: radRequestInprogressData?.payload?.meta.count || 0,
		},
		{
			name: 'Completed',
			count: radRequestCompletedData?.payload?.meta?.count || 0,
		},
	];

	return {
		LineOneData,
		UsersCardData,
		DepartmentTableData,
		PaidInvoiceData: paidInvoiceForLast30Days?.payload,
		CurrencyCode: currencyValue?.payload?.code,
		MedicationOrderData: medicationOrderData?.payload.data.slice(0, 5),
		CurrentDateMedication: completetdMedicationOrderForCurrentDate?.payload?.data,
		BloodBankData,
		LabRequestData,
		RadRequestData,
		MedicationRequest: medicationRequestData?.payload?.data.slice(0, 5),
		FilteredAppointment: filterAppointments(),
		InstockAndOutOfStockProducts: filterInstockAndOutOfStockProducts()?.slice(0, 10),
	};
};

export default useHandleDashboardUserData;
